<template>
    <v-menu open-on-hover offset-x>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-inline-block">
                <slot></slot>
            </div>
        </template>
        <v-card v-if="app.active_resource === 'ncu'" width="400px">
            <v-container fluid>
                <v-row dense>
                    <v-col cols="6">
                        <v-card flat class="grey lighten-3">
                            <v-card-title class="caption pb-1">vCPU</v-card-title>
                            <v-card-text class="text-h6">{{ currentNCU(app.ncu).cpu }}</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card flat class="grey lighten-3">
                            <v-card-title class="caption pb-1">Memory</v-card-title>
                            <v-card-text class="text-h6">{{ toLocaleFixed(currentNCU(app.ncu).memory, 0) }} GB</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card v-else width="400px">
            <v-container fluid>
                <v-row dense>
                    <v-col cols="6" v-if="currentNodePool(app.node_pool).cpu">
                        <v-card flat class="grey lighten-3">
                            <v-card-title class="caption pb-1">vCPU</v-card-title>
                            <v-card-text class="text-h6">{{ currentNodePool(app.node_pool).cpu }}</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" v-if="currentNodePool(app.node_pool).memory">
                        <v-card flat class="grey lighten-3">
                            <v-card-title class="caption pb-1">Memory</v-card-title>
                            <v-card-text class="text-h6">{{ toLocaleFixed(currentNodePool(app.node_pool).memory, 0) }} GB</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" v-if="currentNodePool(app.node_pool).gpu_type">
                        <v-card flat class="grey lighten-3">
                            <v-card-title class="caption pb-1">GPU Model</v-card-title>
                            <v-card-text class="text-h6">{{ currentNodePool(app.node_pool).gpu_type }}</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" v-if="currentNodePool(app.node_pool).vram">
                        <v-card flat class="grey lighten-3">
                            <v-card-title class="caption pb-1">GPU Memory</v-card-title>
                            <v-card-text class="text-h6">{{ toLocaleFixed(currentNodePool(app.node_pool).vram, 0) }} GB</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" v-if="currentNodePool(app.node_pool).ssd">
                        <v-card flat class="grey lighten-3">
                            <v-card-title class="caption pb-1">Temp storage</v-card-title>
                            <v-card-text class="text-h6">{{ toLocaleFixed(currentNodePool(app.node_pool).ssd, 0) }} GB</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-menu>
</template>

<script>
import { scaling } from '@/mixins/scaling'

export default {
    name: 'AppScaleInfoMenu',
    components: {},
    mixins: [scaling],
    props: {
        app: Object
    },
    data() {
        return {}
    },
    created() {},
    beforeDestroy() {},
    methods: {},
    computed: {},
    watch: {}
}
</script>
